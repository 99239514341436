<template>
  <div class="screen-admin-page screen-admin-usaga-report">
    <div>
      <div class="toolbar">
        <div class="title">
          <h1>Invoice Report {{currentDay.format('MMM YYYY')}}</h1>
          <div class="search-overlay" v-if="data.openSearchTab">
            <div class="wrapper">
              <div class="header">
                <input type="text" v-model="data.inputVal" placeholder="Search">
                <button class="close-btn" @click="close()">X</button>
              </div>
              <div v-if="data.suggestionList" class="suggestion-list"
                :class="data.suggestionList && data.suggestionList.docs &&
                data.suggestionList.docs.length > 0 ? 'add-height': ''">
                <div>
                  <a :href="consoleLink+'admin/invoice-list/'+item.invoice_number+'/preview?search='+data.inputVal"
                    v-for="item in data.suggestionList.docs" :key="item.key" class="list-wrapper">
                    <div class="align-left">
                      <span>{{item.owner_name}}</span>
                      <p>{{item.project_key}}</p>
                    </div>
                    <div class="align-right add-padding">
                      <span>{{item.invoice_number}}</span>
                      <span>{{item.project_name}}</span>
                      <span>{{item.owner_email}}</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tools">
          <span class="search-icon"  @click="data.openSearchTab = true">
            Search
            <svg>
              <use v-bind="{'xlink:href':'#search-icon'}" />
            </svg>
          </span>
          <router-link
            :to="{query: {month: prevMonth.format('YYYY-MM')}}"
            v-if="prevMonth"
            class="btn"
          >{{prevMonth.format('MMM YYYY')}}</router-link>
          <button
            class="btn"
            disabled
          >{{currentDay.format('MMM YYYY')}}</button>
          <router-link
            :to="{query: {month: nextMonth.format('YYYY-MM')}}"
            v-if="nextMonth"
            class="btn"
          >{{nextMonth.format('MMM YYYY')}}</router-link>
        </div>
      </div>

      <rz-request-state :state="state" />
      <div
        class="usage-report admin-screen-content"
        v-if="state.status.isLoaded"
      >
        <div class="item-list-row item-list">
          <InvoiceReportSummary
            class="item-list-col"
            :title="`Summary of ${currentDay.format('MMM YYYY')}`"
            :report="report.fulfilled" />
          <InvoiceReportSummary
            class="item-list-col"
            :title="`Unpaid of ${currentDay.format('MMM YYYY')}`"
            :report="report.notFulfilled" />
          <InvoiceReportSummary
            class="item-list-col"
            :title="`Cancelled of ${currentDay.format('MMM YYYY')}`"
            :report="report.cancelled" />
        </div>
        <div class="item-list-row item-list">
          <TotalReport
            class="item-list-col"
            :title="`Paid Report of  ${data.labelContent}`"
            :report="totalReport" />
         <TotalReport
            class="item-list-col"
            :title="`UnPaid Report of  ${data.labelContent}`"
            :report="totalReport"
            :unpaid = true />
          <div class="date-picker">
            <p @click="data.showDropdown = !data.showDropdown">
              {{data.labelContent}}
              <svg>
                <use v-bind="{'xlink:href':'#dropdown-icon'}"></use>
              </svg>
            </p>
            <div v-if="data.showDropdown">
              <span  v-for="(year, index) in years" :key="year.key"
              @click="chosenYear(index)">{{year}}</span>
            </div>
          </div>
        </div>
        <hr />
        <div class="download-btn-wrapper">
          <button class="download-btn" @click="downloadInvoice()">
            Download Invoice
          </button>
        </div>
        <FilterableView
          :filterables="filterables"
          @item-select="onSelectedFilteritem"
          @item-toggle="onFilterItemToggle"
        >
          <div
            v-if="filterables.activeItem === 'paidInvoices'"
            class="item-list-row item-list"
          >
            <InvoiceReportTable
              :report="report.fulfilled"
              @on-invoice-click="showPreviewInvoice"
              title="Paid Invoices"
            />
          </div>
          <div
            v-if="filterables.activeItem === 'unpaidInvoices'"
            class="item-list-row item-list"
          >
            <InvoiceReportTable
              :report="report.notFulfilled"
              @on-invoice-click="showPreviewInvoice"
              title="Unpaid Invoices"
            />
          </div>
          <div
            v-if="filterables.activeItem === 'cancelledInvoices'"
            class="item-list-row item-list"
          >
            <InvoiceReportTable
              :report="report.cancelled"
              @on-invoice-click="showPreviewInvoice"
              title="Cancelled Invoices"
            />
          </div>
          <div
            v-if="filterables.activeItem === 'approvedInvoices'"
            class="item-list-row item-list"
          >
            <InvoiceReportTable
              :report="report.approved"
              @on-invoice-click="showPreviewInvoice"
              title="Approved Invoices"
            />
          </div>
        </FilterableView>
      </div>
    </div>
    <QuickPreviewInvoices />
  </div>
</template>
<script>
import moment from 'moment';
import {
  ref,
  reactive,
  computed,
  onMounted,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  ProjectList,
  InvoiceReport,
  FilterableItem,
  Filterables,
  IgnoredInvoiceGroups,
  DefaultInvoiceGroupsOrder,
} from '@/store/modules/admin';
import { currencyMethodsMixin } from '@/mixin/currency';
import FilterableView from '@/components/admin/Filterable.vue';
import InvoiceReportSummary from '@/components/admin/InvoiceReportSummary.vue';
import TotalReport from '@/components/admin/TotalReport.vue';
import InvoiceReportTable from '@/components/admin/InvoiceReportTable.vue';
import QuickPreviewInvoices from '@/components/admin/QuickPreviewInvoices.vue';
import Config from '@/config';

export default {
  mixins: [currencyMethodsMixin],
  components: {
    FilterableView,
    InvoiceReportSummary,
    InvoiceReportTable,
    QuickPreviewInvoices,
    TotalReport,
  },
  data() {
    return {
      consoleLink: Config.consoleHost,
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const monthFromQuery = () => {
      if (route.query.month) {
        return moment(route.query.month);
      }
      return null;
    };
    let month = ref({}) || route.query.month;
    let year = ref({}) || route.query.year;
    const state = ref(new RequestState());
    const report = ref({});
    const currentDay = ref(monthFromQuery() || moment());
    const prevMonth = ref(null);
    const nextMonth = ref(null);
    const fulfilledItems = ref([]);
    const notFulfilledItems = ref([]);
    const cancelledItems = ref([]);
    const approvedItems = ref([]);
    const activeItems = ref([]);
    const totalReport = ref({});
    const previewInvoice = ref({});
    const years = ref([]);
    const filterables = ref(new Filterables({ items: [] }));
    const ignoreInvoiceGroups = ref(IgnoredInvoiceGroups.map((x) => x.value));

    const defaultInvoiceGroups = [...DefaultInvoiceGroupsOrder];
    const data = reactive({
      openSearchTab: false,
      inputVal: null,
      suggestionList: {},
      labelContent: new Date().getFullYear(),
      showDropdown: false,
    });

    const close = () => {
      data.suggestionList = null;
      data.inputVal = '';
      data.openSearchTab = false;
    };

    watch(() => data.inputVal, () => ProjectList.searchQuery(data.inputVal, 'invoice').then((list) => {
      data.suggestionList = list.data.data;
    }));

    const showPreviewInvoice = (invoice, val) => {
      let showProjectInfo;
      if (filterables.value.activeItem) {
        router.push({
          name: 'adminQuickInvoicePreview',
          params: {
            type: filterables.value.activeItem,
            key: invoice.key,
          },
          query: {
            // eslint-disable-next-line no-underscore-dangle
            year,
            month,
          },
        });
      } else {
        router.push({
          name: 'adminQuickInvoicePreview',
          params: {
            type: 'paidInvoices',
            key: invoice.key,
          },
          query: {
            year,
            month,
          },
        });
      }
      route.query = showProjectInfo;
      route.query.showProjectInfo = val;
      previewInvoice.value = invoice;
    };

    const showPreviewInvoiceUsingKey = (key) => {
      InvoiceReport.fetchInvoice(key).then((response) => {
        showPreviewInvoice(response);
      });
    };

    const hidePreviewInvoice = () => {
      previewInvoice.value = {};
      router.push({
        name: 'invoiceList',
      });
      store.commit('admin/updatePreviewInvoice', { invoice: null });
      return false;
    };

    const updateFilterItems = () => {
      const fulfilled = [...fulfilledItems.value];
      const notFulfilled = [...notFulfilledItems.value];
      const cancelled = [...cancelledItems.value];
      const approved = [...approvedItems.value];
      let allItems = [...fulfilled];
      if (filterables.value.activeItem === 'unpaidInvoices') {
        allItems = [...notFulfilled];
      }
      if (filterables.value.activeItem === 'cancelledInvoices') {
        allItems = [...cancelled];
      }
      if (filterables.value.activeItem === 'approvedInvoices') {
        allItems = [...approved];
      }

      const invoiceGroups = {};
      defaultInvoiceGroups.forEach((group) => {
        invoiceGroups[group.value] = {
          group,
          count: 0,
        };
      });
      const items = [
        new FilterableItem({
          name: 'Fullfilled Invoices',
          key: 'paidInvoices',
          isCheckbox: false,
          number: fulfilledItems.value.length,
        }),
        new FilterableItem({
          name: 'Unpaid Invoices',
          key: 'unpaidInvoices',
          isCheckbox: false,
          number: notFulfilledItems.value.length,
        }),
        new FilterableItem({
          name: 'Cancelled Invoices',
          key: 'cancelledInvoices',
          isCheckbox: false,
          number: cancelledItems.value.length,
        }),
        new FilterableItem({
          name: 'Approved Invoices',
          key: 'approvedInvoices',
          isCheckbox: false,
          number: approvedItems.value.length,
        }),
      ];

      allItems.forEach((invoice) => {
        if (!invoiceGroups[invoice.project_group]) {
          const name = invoice.project_group.replace('_', ' ');
          invoiceGroups[invoice.project_group] = {
            group: { name },
            count: 0,
          };
        }

        if (!invoiceGroups[invoice.invoice_type]) {
          const name = invoice.invoice_type.replace('_', ' ');
          invoiceGroups[invoice.invoice_type] = {
            group: { name },
            count: 0,
          };
        }

        if (!invoiceGroups.approved_invoice) {
          const name = 'approved invoice';
          invoiceGroups.approved_invoice = {
            group: { name },
            count: 0,
          };
        }

        invoiceGroups[invoice.project_group].count += 1;
        invoiceGroups[invoice.invoice_type].count += 1;
        invoiceGroups.approved_invoice.count += 1;
      });

      if (invoiceGroups) {
        items.push(FilterableItem.SeperatorItem());
        Object.keys(invoiceGroups).forEach((key) => {
          const value = invoiceGroups[key];
          const name = value.group.key;
          items.push(
            new FilterableItem({
              name: `Hide ${name}`,
              key,
              isCheckbox: true,
              number: value.count,
              selected: ignoreInvoiceGroups.value.indexOf(key) > -1,
            }),
          );
        });
      }

      report.value.fulfilled.items = fulfilled.filter(
        (invoice) => ignoreInvoiceGroups.value.indexOf(invoice.project_group) < 0,
      );
      report.value.notFulfilled.items = notFulfilled.filter(
        (invoice) => ignoreInvoiceGroups.value.indexOf(invoice.project_group) < 0,
      );
      report.value.cancelled.items = cancelled.filter(
        (invoice) => ignoreInvoiceGroups.value.indexOf(invoice.project_group) < 0,
      );

      if (!filterables.value.activeItem) {
        filterables.value.activeItem = 'paidInvoices';
      }

      filterables.value.items = items;

      if (filterables.value.activeItem === 'unpaidInvoices') {
        activeItems.value = report.value.notFulfilled.items;
        items.forEach((item) => {
          if (item.key === 'billing' && item.selected) {
            report.value.notFulfilled.items = report.value.notFulfilled.items.filter(
              (invoice) => invoice.invoice_type !== 'billing'
               && ignoreInvoiceGroups.value.indexOf(invoice.project_group) < 0,
            );
          } else if (item.key === 'purchase' && item.selected) {
            report.value.notFulfilled.items = report.value.notFulfilled.items.filter(
              (invoice) => invoice.invoice_type !== 'purchase'
               && ignoreInvoiceGroups.value.indexOf(invoice.project_group) < 0,
            );
          } else if (item.key === 'approved_invoice' && item.selected) {
            report.value.notFulfilled.items = report.value.notFulfilled.items.filter(
              (invoice) => invoice.is_verified === false
               && ignoreInvoiceGroups.value.indexOf(invoice.project_group) < 0,
            );
          }
        });
        report.value.notFulfilled.buildReport();
      } else if (filterables.value.activeItem === 'cancelledInvoices') {
        activeItems.value = report.value.cancelled.items;
        report.value.cancelled.buildReport();
      } else {
        activeItems.value = report.value.fulfilled.items;
        report.value.fulfilled.buildReport();
      }

      // showPreviewInvoiceUsingKey('1381489150917742596');
    };

    const fetchData = () => {
      state.value.status = RequestStateStatus.Loading;
      year = currentDay.value.year();
      month = currentDay.value.format('MM');
      InvoiceReport.fetch(year, month).then((_report) => {
        report.value = _report;
        fulfilledItems.value = [..._report.fulfilled.items];
        notFulfilledItems.value = [..._report.notFulfilled.items];
        cancelledItems.value = [..._report.cancelled.items];
        approvedItems.value = [..._report.approved.items];
        updateFilterItems();
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    const downloadInvoice = () => {
      year = currentDay.value.year();
      month = currentDay.value.format('MM');
      const appkey = Config.rzAppKey;
      const apikey = Config.rzApiKey;
      let url = null;
      // eslint-disable-next-line max-len
      url = `/backend/rest/accounts/business/paygo-orders-report/${year}/${month}/?rz-api-key=${apikey}&rz-app-key=${appkey}`;
      window.open(url, '_blank');
    };

    const getYears = () => {
      const currentYear = new Date().getFullYear();
      const startYear = 2021;
      for (let i = 0; i <= (currentYear - startYear); i += 1) {
        years.value.push(startYear + i);
      }
    };

    const chosenYear = (index) => {
      data.labelContent = years.value[index];
      data.showDropdown = !data.showDropdown;
    };

    const fetchTotalInvoice = () => {
      year = data.labelContent;
      InvoiceReport.fetchTotalReport(year).then((resp) => {
        totalReport.value = resp;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    watch(() => data.labelContent, () => {
      fetchTotalInvoice();
    });

    const updateMonths = () => {
      prevMonth.value = currentDay.value.clone().add(-1, 'months');
      if (moment().isSame(currentDay.value, 'month')) {
        nextMonth.value = null;
      } else {
        nextMonth.value = currentDay.value.clone().add(1, 'months');
      }
    };

    const updateMonth = (monthVal) => {
      currentDay.value = monthVal;
      updateMonths();
      fetchData();
      fetchTotalInvoice();
    };

    const reportItems = computed(() => {
      const items = [
        {
          key: 'eur', name: 'EUR', currency: 'EUR', report: report.value.totalByCurrency.eur,
        },
        {
          key: 'usd', name: 'USD', currency: 'USD', report: report.value.totalByCurrency.usd,
        },
        {
          key: 'inr', name: 'INR', currency: 'INR', report: report.value.totalByCurrency.inr,
        },
        {
          key: 'total', name: 'Total', currency: 'EUR', report: report.value.total,
        },
      ];
      return items;
    });

    const onSelectedFilteritem = (item) => {
      if (item.isCheckbox) {
        return;
      }

      filterables.value.activeItem = item.key;
      updateFilterItems();
    };

    const onFilterItemToggle = (item) => {
      const index = ignoreInvoiceGroups.value.indexOf(item.key);
      if (index > -1) {
        ignoreInvoiceGroups.value.splice(index, 1);
      } else {
        ignoreInvoiceGroups.value.push(item.key);
      }

      updateFilterItems();
    };

    const onInvoiceClick = (invoice) => {
      showPreviewInvoiceUsingKey(invoice.key);
    };

    watch(() => route.query, () => {
      if (route.query.month) {
        const newMonth = monthFromQuery();
        if (currentDay.value !== newMonth) {
          updateMonth(newMonth);
        }
      }
    });

    watch(() => filterables, () => {
      updateFilterItems();
    });

    onMounted(() => {
      updateMonths();
      fetchData();
      fetchTotalInvoice();
      getYears();
    });

    return {
      state,
      report,
      updateMonth,
      currentDay,
      totalReport,
      prevMonth,
      nextMonth,
      reportItems,
      filterables,
      onSelectedFilteritem,
      onFilterItemToggle,
      activeItems,
      onInvoiceClick,
      previewInvoice,
      showPreviewInvoiceUsingKey,
      showPreviewInvoice,
      hidePreviewInvoice,
      data,
      downloadInvoice,
      close,
      chosenYear,
      years,
    };
  },
};
</script>
<style lang="scss" scoped>
.download-btn-wrapper {
  display: flex;
  justify-content: end;
  button {
    padding: size(10) size(8);
    border-radius: size(6);
    background: #5168e5;
    margin-top: size(30);
    font-size: size(14);
    cursor: pointer;
  }
}
.date-picker {
  p {
    padding: size(10) size(8);
    background: #272a44;
    border-radius: size(6);
    cursor: pointer;
    svg {
      width: size(10);
      height: size(10);
      fill: var(--global--white--Color);
      margin-left: size(5);
      display: inline-block;
    }
  }
  div {
    margin-top: size(10);
    background: #272a44;
    border-radius: size(6);
    padding: size(10) size(6);
    span {
      display: block;
      padding-bottom: size(8);
      cursor: pointer;
    }
  }
}
</style>
