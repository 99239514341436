<template>
  <div class="item-report-summary invoice-report-items">
    <div
      class="summary-toolbar"
      v-if="title"
    >
      <h2>{{title}}</h2>
    </div>

    <table>
      <thead>
        <tr>
          <th></th>
          <th>Sales</th>
        </tr>
      </thead>
      <tbody v-if="!unpaid">
        <tr
          v-for="(item, index) in report.paid_invoices"
          :key="item.key"
          :class="[item.key]"
        >
          <td>{{index.toUpperCase()}}</td>
          <td>{{formatCurrency(item, index, true)}}</td>
        </tr>
        <tr>
          <td>
            Total Paid
          </td>
          <td>{{formatCurrency(report.total_paid_eur_converted, 'eur', true)}}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          v-for="(item, index) in report.unpaid_invoices"
          :key="item.key"
          :class="[item.key]"
        >
          <td>{{index.toUpperCase()}}</td>
          <td>{{formatCurrency(item, index, true)}}</td>
        </tr>
        <tr>
          <td>
            Total UnPaid
          </td>
          <td>{{formatCurrency(report.total_unpaid_eur_converted, 'eur', true)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { currencyMethodsMixin } from '@/mixin/currency';

export default {
  props: {
    report: Object,
    title: {
      type: String,
      default: '',
    },
    unpaid: Boolean,
  },
  mixins: [currencyMethodsMixin],
};
</script>
